/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

/**
 * Finds the value between a and b given a percentage
 * @param {number} a the 0% value
 * @param {number} b the 100% value
 * @param {number} percent the percentage as a 0 to 1 number
 */
function lerp(a, b, percent) {
    return a + percent * (b - a)
}

import BannerComponent from './components/banner.component'
import NavbarComponent from './components/navbar.component'

document.addEventListener('DOMContentLoaded', () => {
    //Component
    new BannerComponent()
    new NavbarComponent()

    //Front page only
    if ($('.home').length > 0) {
        import(
            '@scripts/services/home.service' /* webpackChunkName: "scripts/home.service" */
        ).then(({ default: HomeService }) => {
            new HomeService()
        })
    }

    //Fullscreen template only
    if ($('.fullscreen').length > 0) {
        import(
            '@scripts/services/layout.service' /* webpackChunkName: "scripts/layout.service" */
        ).then(({ default: LayoutService }) => {
            new LayoutService()
        })
    }

    //On scroll
    let decorationOpacity = 0.3
    document.addEventListener(
        'scroll',
        () => {
            decorationOpacity = lerp(0.3, 0, Math.min(window.scrollY / 2, 100) / 100).toFixed(2)
            window.scrollY < 200
                ? document.documentElement.style.setProperty(
                      '--heading-decoration-color',
                      `rgba(255,255,255,${decorationOpacity})`,
                  )
                : document.documentElement.style.setProperty(
                      '--heading-decoration-color',
                      'transparent',
                  )

            window.scrollY > 0
                ? document
                      .querySelector('.menu')
                      .style.setProperty('--heading-decoration-color', 'transparent')
                : document
                      .querySelector('.menu')
                      .style.setProperty('--heading-decoration-color', 'rgba(255,255,255,0.3)')
        },
        { passive: true },
    )

    //Managing the scroll reveals
    const elementViewObserver = new IntersectionObserver(
        (entries, observer) => {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    entry.target.classList.add('viewed')
                    entry.target.classList.add('in-view')
                } else {
                    entry.target.classList.remove('in-view')
                }
            }
        },
        { rootMargin: '0px 0px -30% 0px' },
    )

    const sections = document.querySelectorAll('section')
    for (const section of sections) {
        elementViewObserver.observe(section)
    }

    const headerHeadings = document.querySelectorAll('.header__heading')
    for (const section of headerHeadings) {
        section.classList.add('viewed')
    }

    elementViewObserver.observe(document.querySelector('.footer'))

    //Toggling between closing menu and closing sub-menu
    $(
        '.menu__item__link[data-trigger="wpiris-menu-more"], .navbar__item[data-trigger="wpiris-menu-prev"]',
    ).on('click', () => {
        $('.navbar--mobile__topbar .navbar__item').toggle()
    })
})
